<template>
  <v-container>
    <base-material-card
      color="indigo"
      icon="mdi-home-search"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Показ
        </div>
      </template>
      <v-row
      >
        <v-col cols="12" md="3">
          <v-text-field
            v-model="search"
            label="Мінімальна ціна"
            clearable
            @input="searchResults"
            @click:clear="searchResults"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="search"
            label="Максимальна ціна"
            clearable
            @input="searchResults"
            @click:clear="searchResults"
          />
        </v-col>
        <v-spacer/>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="toggleModal()"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Додати
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-3 mb-3" />

      <v-list-item-group
        :key="renderListKey"
        v-model="activeSubCategory"
        light
      >
        <draggable
            v-model="tableData"
          >
            <v-list-item
              v-for="(child, i) in tableData"
              :key="`child_${child.title + i}`"
              :class="['pl-10']"
            >
              <v-list-item-title>
                {{child.title}}
              </v-list-item-title>
              <v-icon
                right
                @click="selectItem(child)"
              >
                mdi-eye
              </v-icon>
            </v-list-item>
          </draggable>
      </v-list-item-group>
    </base-material-card>
    <ItemModal
      :modal-is-show="modalIsShow"
      :item="currentItem"
      @closeModal="toggleModal"
      v-if="currentItem"
    />
    <!-- <CategoryModal
      :modal-is-show="categoryModalIsShow"
      :edit-mode="editMode"
      :current-data="currentItem"
      @create="fetchData"
      @closeModal="toggleModal"
    /> -->
  </v-container>
</template>
<script>
import draggable from 'vuedraggable';
import ItemModal from './ItemModal.vue';
// import CategoryModal from './CategoryModal';
// import { CategoryApi } from '@/js/api/Category';
// import { SUCCESS_SWAL_CONF } from '@/js/configs/swal.js';

export default {
  name: 'CategoryPage',
  components: {
    ItemModal,
    draggable,
    // CategoryModal
  },
  data () {
    return {
      modalIsShow: false,
      editMode: false,
      tableData: [
        {
          title: '(20000$) - 10 сот - вул. Лесі Українки'
        },
        {
          title: '(30000$) - 10 сот - вул. Богдана Хмельницького'
        },
        {
          title: '(25000$) - 10 сот - вул. Набережна'
        },
        {
          title: '(45000$) - 10 сот - вул. Незалежності'
        },
        {
          title: '(18000$) - 10 сот - вул. Лесі Українки'
        },
        {
          title: '(20000$) - 10 сот - вул. Лесі Українки'
        },
        {
          title: '(30000$) - 10 сот - вул. Богдана Хмельницького'
        },
        {
          title: '(25000$) - 10 сот - вул. Набережна'
        },
        {
          title: '(45000$) - 10 сот - вул. Незалежності'
        },
        {
          title: '(18000$) - 10 сот - вул. Лесі Українки'
        },

      ],
      loading: true,
      renderListKey: 0,
      activeSubCategory: 0,
      search: '',
      currentItem: null
    }
  },
  watch: {
    tableData: {
      handler() {
        this.renderListKey = this.renderListKey + 1;
      },
      deep: true
    }
  },
  async created() {
    // await this.fetchData();
  },
  methods: {
    toggleModal () {
      this.modalIsShow = !this.modalIsShow;
    },
    async fetchData () {
      this.loading = true;
      try {
        const { data } = await CategoryApi.fetchAll();
        this.tableData = data.data.sort((a,b) => {
          return a.order > b.order ? 1 :
            a.order < b.order ? -1 : 0
        });
      } catch (error) {
        console.log('error', { error });
      } finally {
        this.loading = false;
        this.currentItem = null;
      }
    },
    async categoryOrderUpdate() {
      this.loading = true;
      try {
        const { data } = await CategoryApi.orderUpdate({ids: this.tableData});
        this.tableData = data.data.sort((a,b) => {
          return a.order > b.order ? 1 :
            a.order < b.order ? -1 : 0
        });
        this.$swal.fire(SUCCESS_SWAL_CONF);
      } catch (error) {
        console.log('error', { error });
      } finally {
        this.loading = false;
      }
    },
    searchResults() {

    },
    selectItem(item) {
      this.currentItem = item
      this.toggleModal()
    }
  }
};
</script>

